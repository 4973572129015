/**
 * This function is a shorthand for the window load event
 * @param callback
 */
export function onLoad(callback) {
    window.addEventListener('load', function() {
        callback();
    }, false);
}
// attach to window so that it can be used in the front-end
window.onLoad = onLoad;

/**
 * This function is a shorthand for the window DOMContentLoaded event
 * @param callback
 */
export function domLoaded(callback) {
    document.addEventListener('DOMContentLoaded', function () {
        callback();
    }, false);
}
// attach to window so that it can be used in the front-end
window.domLoaded = domLoaded;