console.log("ge")
// projecvt styling
import '../scss/_main.scss'

// base scripts
// do not change
import './base/index';

// Import plugins
// here you are able to add you own scripts
import './plugins';

