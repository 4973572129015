import Splide from '@splidejs/splide';
import {onLoad} from "../../base/onLoad";

onLoad(() => {
    const splideClass = '.js-players-slider';
    const elements = document.querySelectorAll(splideClass);

    elements.forEach(element => {
        const splide = new Splide(element, {
            type: 'loop',
            rewind: true,
            perPage: 4,
            gap: '4rem',
            arrows: false,

            breakpoints: {
                767: {
                  perPage: 1,
                },
                902: {
                    perPage: 2
                },
                1350: {
                    perPage: 3
                }
            }
        });

        splide.mount();

        const prev = element.querySelector('.prev');
        const next = element.querySelector('.next');

        prev.addEventListener('click', () => {
            splide.go('<');
        });

        next.addEventListener('click', () => {
            splide.go('>');
        });
    });
})