import {onLoad} from "../base/onLoad";

onLoad(() => {
    const faqItems = document.querySelectorAll('.faq-item');

    if(faqItems.length === 0) {
        return;
    }

    const hideAll = () => {
        faqItems.forEach(item => {
            item.classList.remove('active');
        });
    };

    faqItems.forEach(faqItem => {
        const switchButton = faqItem.querySelector('.faq-switch');
        switchButton.addEventListener('click', () => {
            const isActive = faqItem.classList.contains('active');

            hideAll();

            if(!isActive) {
                faqItem.classList.add('active');
            }
        });
    });

    faqItems.item(0).classList.add('active');
})